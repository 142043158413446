:root {
  --primary: 34, 100, 229;
  --secondary: 238, 238, 238;
  --white: 255, 255, 255;
  --black: 0, 0, 0;
  --text: 70, 79, 96;
  --stroke: 229, 229, 229;
  --green: 73, 235, 16;
  --error: 194, 51, 60;
  --gray: 154, 154, 154;
  --gray-light: 249, 250, 252;
  --gray-blue: 188, 194, 206;
  --blue: 233, 239, 252;
  --blue-light: 247, 249, 253;
  --blue-hover: 101, 145, 231;
  --blue-pressed: 16, 72, 182;
  --vk: 0, 119, 255;
  --discord: 86, 98, 246;
  --telegram: 55, 174, 226;
  --twitter: 85, 172, 238;
  --facebook: 59, 89, 152;
}
