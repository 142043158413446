@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}

@layer components {
}

@layer utilities {
}
